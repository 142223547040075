import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.min.js'

import '../src/assets/css/bootstrap.min.css'
import '../src/assets/css/animate.css'
import '../src/assets/css/swiper-bundle.css'
import '../src/assets/css/slick.css'
import '../src/assets/css/magnific-popup.css'
import '../src/assets/css/font-awesome-pro.css'
import '../src/assets/css/flaticon_mycollection.css'
import '../src/assets/css/spacing.css'
import '../src/assets/css/main.css'

import './css/slick.css';

import '../src/assets/css/font-awesome-pro.css'
import '../src/App.css'

import '../src/assets/js/main.js'

import loadeImg from '../src/assets/img/logo1/preloader/preloader-icon.png';

import Markup from './markup/markup';

function App() {
  return (
    <div classNameName="App">
      <div id="loading">
            <div id="loading-center">
                <div id="loading-center-absolute">
                    <div className="tp-preloader-content">
                        <div className="tp-preloader-logo">
                            <div className="tp-preloader-circle">
                                <svg width="190" height="190" viewBox="0 0 380 380" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <circle stroke="#D9D9D9" cx="190" cy="190" r="180" stroke-width="6" stroke-linecap="round">
                                    </circle>
                                    <circle stroke="red" cx="190" cy="190" r="180" stroke-width="6" stroke-linecap="round"></circle>
                                </svg>
                            </div>
                            <img src={loadeImg} alt="Loader" />
                        </div>
                        <p className="tp-preloader-subtitle">Loading...</p>
                    </div>
                </div>
            </div>
        </div>
        <div className="back-to-top-wrapper">
            <button id="back_to_top" type="button" className="back-to-top-btn">
                <svg width="12" height="7" viewBox="0 0 12 7" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M11 6L6 1L1 6" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>               
            </button>
        </div>
      <Markup />
    </div>
  );
}

export default App;
